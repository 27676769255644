import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import type { IBookPrice } from '@shared/jp/data/books/prices/types';
import { MediaService } from '@studiobuki/web-core/lib/media';

@Component({
  selector: 'app-mobile[id][title][specs][photo]',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileComponent {
  @Input() id!: string;

  @Input() title!: string;

  /** supports html tags */
  @Input() subtitle!: string;

  @Input() specs!: { [key: string]: string };

  @Input() price: IBookPrice | undefined;

  @Input() photo!: string;

  @Input() discount: string | undefined;

  public Object = Object;

  get specsKeys() {
    return Object.keys(this.specs);
  }

  constructor(public media: MediaService) {}
}
