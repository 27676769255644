// import {
//   ROUTE_PATH_ABOUT,
//   ROUTE_PATH_COLLABORATION,
//   ROUTE_PATH_FAQ,
//   ROUTE_PATH_ORDERCONTRACT,
//   ROUTE_PATH_POLICY,
//   ROUTE_PATH_TERMS,
// } from 'app/app-routing.constants';
// import { LINK_BLOG, LINK_INSTAGRAM } from 'src/app/constants';
import type { TFooterMenu } from '@shared/common/data/footer/types';
import {
  ROUTE_PATH_ORDERCONTRACT,
  ROUTE_PATH_ORDERCONTRACT_GIFT,
  ROUTE_PATH_POLICY,
  ROUTE_PATH_TERMS,
} from '@studiobuki/web-core/lib/routing';

export const footerMainMenu: TFooterMenu = [
  {
    title: 'このサイトについて',
    link: '/about',
  },
  {
    title: 'FAQ',
    link: '/faq',
  },
  {
    title: 'お問い合わせ',
    link: '/contacts',
  },

  // {
  //   title: 'BÜKIについて',
  //   link: `/${ROUTE_PATH_ABOUT}`,
  // },
  // {
  //   title: 'FAQ',
  //   link: `/${ROUTE_PATH_FAQ}`,
  // },
  // {
  //   title: '法人向けサービス',
  //   link: `/${ROUTE_PATH_COLLABORATION}`,
  // },
  // {
  //   title: 'マガジン',
  //   href: LINK_BLOG,
  // },
  // {
  //   title: '@buki.jp',
  //   href: LINK_INSTAGRAM,
  //   svgIconSrc: 'assets/images/icons/instagram.svg',
  // },
];

export const footerSideMenu: TFooterMenu = [
  {
    title: '特定商取引法に基づく表示',
    link: ROUTE_PATH_ORDERCONTRACT,
  },
  {
    title: '資金決済法に基づく表示',
    link: ROUTE_PATH_ORDERCONTRACT_GIFT,
  },
  {
    title: '利用規約',
    link: ROUTE_PATH_TERMS,
  },
  {
    title: 'プライバシーポリシー',
    link: ROUTE_PATH_POLICY,
  },
  // {
  //   title: '特定商取引法に基づく表示',
  //   link: `/${ROUTE_PATH_ORDERCONTRACT}`,
  // },
  // {
  //   title: '利用規約',
  //   link: `/${ROUTE_PATH_TERMS}`,
  // },
  // {
  //   title: 'プライバシーポリシー',
  //   link: `/${ROUTE_PATH_POLICY}`,
  // },
];
