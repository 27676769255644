import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EGiftOrderCodeType } from '@shared/common/gift/enums';
import { createValidator } from '@shared/common/data/books/inputs/validators.functions';
import Subscriber from '@shared/common/subscriber';
import { FirebaseService } from 'src/app/services/firebase.service';
import { MediaService } from '@studiobuki/web-core/lib/media';
import type { IFormGiftSpecialData } from './form-gift-special.interfaces';

@Component({
  selector: 'app-form-gift-special',
  templateUrl: './form-gift-special.component.html',
  styleUrls: ['./form-gift-special.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGiftSpecialComponent implements OnInit, OnDestroy {
  /** `undefined` when invalid */
  @Input() set data(data: IFormGiftSpecialData | undefined) {
    if (!isEqual(data, this.data)) {
      this._data$.next(data);
      this.dataChange.emit(data);

      if (data && !isEqual(data, this.formGroup.value)) {
        this.formGroup.setValue(data);
      }
    }
  }

  get data(): IFormGiftSpecialData | undefined {
    return this._data$.value;
  }

  private _data$ = new BehaviorSubject<IFormGiftSpecialData | undefined>(
    undefined,
  );

  public data$ = this._data$.asObservable();

  @Output() dataChange = new EventEmitter<IFormGiftSpecialData | undefined>();

  @Input() set touchEventEmitter(e: EventEmitter<void>) {
    e.subscribe(() => {
      this.formGroup.markAllAsTouched();
    });
  }

  public formGroup = new FormGroup({
    count: new FormControl(1, {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.min(1),
        Validators.max(1000),
      ],
    }),
    expiration: new FormControl(
      ((date) => {
        // set the next year as the expiration date
        date.setFullYear(date.getFullYear() + 1);

        return date;
      })(new Date()),
      { nonNullable: true },
    ),
    password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    campaignCode: new FormControl('1', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    campaignDescription: new FormControl('なし', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    codeType: new FormControl(EGiftOrderCodeType.special.toString(), {
      nonNullable: true,
    }),
    campaignNumber: new FormControl('', {
      nonNullable: true,
      validators: [
        createValidator((control) => {
          let forbidden = false;
          const { value: campaignNumber, parent } = control;

          const codeTypeFC = parent?.get('codeType');

          if (!codeTypeFC) return forbidden;

          const { value: codeType } = codeTypeFC;

          if (codeType === EGiftOrderCodeType.special.toString()) {
            forbidden = !(
              campaignNumber === '' || /^\d{3}$/g.test(campaignNumber)
            );
          }

          return forbidden;
        }),
      ],
    }),
    labelTag: new FormControl('0', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    statusTag: new FormControl('0', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    extraTag: new FormControl('0', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  private readonly _sub = new Subscriber();

  public readonly expirationMinDate = ((date) => {
    // set the next year as the expiration date
    date.setDate(date.getDate() + 1);

    return date;
  })(new Date());

  public readonly EGiftOrderCodeType = EGiftOrderCodeType;

  constructor(
    private _firebaseService: FirebaseService,
    public media: MediaService,
  ) {}

  ngOnInit() {
    this._sub.push(
      this.formGroup.valueChanges.subscribe((data): void => {
        this.data = this.formGroup.valid
          ? this.formGroup.getRawValue()
          : undefined;
      }),
      this._firebaseService.userData$.subscribe((userData) => {
        const { adminPassword } = userData;

        this.formGroup.controls.password.setValue(adminPassword || '');
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
