import type { AfterContentInit, OnDestroy } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import Subscriber from '@shared/common/subscriber';
import { ToggleCardsItemComponent } from '../toggle-cards-item/toggle-cards-item.component';

type TValue = any | undefined;

@Component({
  selector: 'app-toggle-cards',
  templateUrl: './toggle-cards.component.html',
  styleUrls: ['./toggle-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleCardsComponent implements AfterContentInit, OnDestroy {
  @Input() set value(value: TValue) {
    if (this.value !== value) {
      this._value = value;
      this.setItems(value);
      this.valueChange.emit(value);
    }
  }

  get value(): TValue {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<TValue>();

  @ContentChildren(ToggleCardsItemComponent)
  items?: QueryList<ToggleCardsItemComponent>;

  private _value: TValue;

  private _sub = new Subscriber();

  private _clickSub = new Subscriber();

  // constructor() {}

  private setItems(value: TValue) {
    this.items?.forEach((item) => {
      item.active$.next(item.value === value);
    });
  }

  private initItems() {
    this.items?.forEach((item) => {
      this._clickSub.push(
        item.click$.subscribe(() => {
          this.value = item.value;
        }),
      );
    });
  }

  ngAfterContentInit() {
    const { items } = this;

    if (!items) throw new Error('missing items');

    this._sub.push(
      items.changes.subscribe(() => {
        this._clickSub.unsubscribe();
        this.setItems(this.value);
        this.initItems();
      }),
    );

    items.notifyOnChanges();
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
