import { Injectable } from '@angular/core';
import type {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import type { TAuthType } from '@studiobuki/web-core/lib/routing';
import {
  ROUTE_PATH_AUTH,
  ROUTE_PATH_MY_PAGE,
  ROUTE_PATH_PARAM_AUTH_TYPE,
  ROUTE_PATH_PARAM_AUTH_TYPE_ARR,
  ROUTE_PATH_PARAM_AUTH_TYPE_REQUIRED_QUERY_PARAM_MAP,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN,
} from '@studiobuki/web-core/lib/routing';
import type { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthPageGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _firebaseService: FirebaseService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const type = next.paramMap.get(ROUTE_PATH_PARAM_AUTH_TYPE) as TAuthType;

    return this._firebaseService.user$.pipe(
      take(1),
      map((user) => {
        if (user.isAnonymous) {
          if (!type) {
            // * default auth path
            return this._router.parseUrl(
              `${ROUTE_PATH_AUTH}/${ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN}`,
            );
          }
          if (ROUTE_PATH_PARAM_AUTH_TYPE_ARR.includes(type)) {
            const requiredQueryParams =
              ROUTE_PATH_PARAM_AUTH_TYPE_REQUIRED_QUERY_PARAM_MAP[type];
            let status = true;

            if (requiredQueryParams) {
              for (let i = 0; i < requiredQueryParams.length; i++) {
                const queryParam = requiredQueryParams[
                  i
                ] as (typeof requiredQueryParams)[number];
                const queryParamValue = next.queryParamMap.get(queryParam);

                if (!queryParamValue) {
                  status = false;
                  break;
                }
              }
            }

            if (status) {
              return true;
            }
          }

          // * redirect to 404 if type is not handled
          return this._router.parseUrl('404');
        }

        return this._router.parseUrl(ROUTE_PATH_MY_PAGE);
      }),
    );
  }
}
