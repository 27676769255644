import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Logger } from '@shared/common/logger';
import Subscriber from '@shared/common/subscriber';
import { FirebaseService } from 'src/app/services/firebase.service';
import type {
  TAuthQueryParams,
  TAuthType,
} from '@studiobuki/web-core/lib/routing';
import {
  ROUTE_PATH_PARAM_AUTH_TYPE,
  ROUTE_PATH_PARAM_AUTH_TYPE_RESET,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP,
  ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL,
} from '@studiobuki/web-core/lib/routing';
import {
  EmailAuthProvider,
  linkWithCredential,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { BASE_HREF } from 'src/app/app.contants';
import { RoutingService } from 'src/app/services/routing.service';
import { getCurrentAnonymousUser } from './auth-page.functions';
import type {
  IResetData,
  ISignInData,
  ISignUpData,
} from './auth-page.interfaces';

const log = new Logger('AuthPageComponent');

@Component({
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss'],
})
export class AuthPageComponent implements OnInit, OnDestroy {
  public type: TAuthType | undefined;

  public queryParams: TAuthQueryParams | undefined;

  public readonly ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN =
    ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN;

  public readonly ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP =
    ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP;

  public readonly ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL =
    ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL;

  public readonly ROUTE_PATH_PARAM_AUTH_TYPE_RESET =
    ROUTE_PATH_PARAM_AUTH_TYPE_RESET;

  private readonly _sub = new Subscriber();

  constructor(
    private _firebaseService: FirebaseService,
    private _routingService: RoutingService,
    private _activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._sub.push(
      this._activatedRoute.paramMap.subscribe((paramMap) => {
        // param check implemented in AuthPageGuard
        this.type = paramMap.get(ROUTE_PATH_PARAM_AUTH_TYPE) as TAuthType;
      }),

      this._activatedRoute.queryParams.subscribe((queryParams) => {
        // queryParams check implemented in AuthPageGuard
        this.queryParams = queryParams as TAuthQueryParams;
      }),
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  public async onSignInButtonClick(data?: ISignInData) {
    log.info('onSignInButtonClick', data);

    if (this.type !== ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN) {
      await this._routingService.goToAuth(ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_IN);
    } else if (data) {
      const { auth: Auth } = this._firebaseService;
      const { email, password } = data;

      const anonymousUser = getCurrentAnonymousUser(Auth);

      if (!anonymousUser) return;

      try {
        await signInWithEmailAndPassword(Auth, email, password);

        // await this._routingService.goToMyPage();
        await this._routingService.goToIndex();
      } catch (error) {
        log.error('onSignInButtonClick', error);
        alert(error instanceof Error ? error.message : error);
      }
    } else {
      log.error('unexpected');
    }
  }

  public async onSignUpButtonClick(data?: ISignUpData) {
    log.info('onSignUpButtonClick', data);

    if (this.type !== ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP) {
      await this._routingService.goToAuth(ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP);
    } else if (data) {
      const { auth: Auth } = this._firebaseService;
      const { email, password } = data;

      const anonymousUser = getCurrentAnonymousUser(Auth);

      if (!anonymousUser) return;

      try {
        const authCredential = EmailAuthProvider.credential(email, password);

        await linkWithCredential(anonymousUser, authCredential);

        // await auth.currentUser.updateProfile({
        //   displayName: `${firstName} ${lastName}`
        // });

        if (Auth.currentUser) {
          await sendEmailVerification(Auth.currentUser, {
            // url: `${BASE_HREF}my-page`,
            url: `${BASE_HREF}`,
          });
        }

        // this._routingService.goToMyPage();
        await this._routingService.goToAuth(
          ROUTE_PATH_PARAM_AUTH_TYPE_SIGN_UP_FINAL,
          {
            email: data.email,
          },
        );
      } catch (error) {
        log.error('onSignUpButtonClick', error);
        alert(error instanceof Error ? error.message : error);
      }
    } else {
      log.error('unexpected');
    }
  }

  public onSignUpFinalButtonClick() {
    log.info('onSignUpFinalButtonClick');
  }

  public async onResetButtonClick(data?: IResetData) {
    log.info('onResetButtonClick', data);

    if (this.type !== ROUTE_PATH_PARAM_AUTH_TYPE_RESET) {
      return this._routingService.goToAuth(ROUTE_PATH_PARAM_AUTH_TYPE_RESET);
    }

    return false;
  }
}
