import { Injectable } from '@angular/core';
import type { EVendor } from '@shared/jp/enums';
import type {
  TBookAlias,
  TBookCover,
  TBookWrapping,
} from '@shared/jp/book/interfaces';
import type {
  IDiscountCampaign,
  IDiscountCampaignFB,
} from '@shared/jp/discount/interfaces';
import type {
  IGiftOrderBook,
  IGiftOrderSpecialCreated,
  IGiftOrderSpecialCreateParams,
  IGiftOrderSpecialGenerated,
  IGiftOrderSpecialPaid,
  IGiftOrderStandartCreated,
  IGiftOrderStandartCreateParams,
  IGiftOrderStandartGenerated,
  IGiftOrderStandartPaid,
  IUserGift,
  IGiftOrderCreateParamsCommon,
  TUserGift,
  TUserGiftError,
  TUserGiftFirestore,
  TGiftOrderCreateParams,
  TGiftOrderCreateParamsError,
  TGiftOrderStandard,
  TGiftOrderSpecial,
  TGiftOrder,
  TGiftOrderError,
  TGiftOrderFirestore,
} from '@shared/jp/gift/interfaces';
import type { IOrderData, ITaskPrintBookData } from '@shared/jp/interfaces';
import type { TBookData } from '@shared/jp/models';
import type {
  IProduction,
  IProductionFB,
} from '@shared/jp/production/interfaces';
import type {
  TRegionNameEnglish,
  TShippingMethod,
} from '@shared/jp/shipping/interfaces';
import { FirebaseService as _FirebaseService } from '@studiobuki/web-core/lib/firebase';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService extends _FirebaseService<
  TBookAlias,
  TBookWrapping,
  TBookCover,
  TShippingMethod,
  TRegionNameEnglish,
  EVendor,
  TBookData,
  IOrderData,
  IProduction,
  IProductionFB,
  IDiscountCampaign,
  IDiscountCampaignFB,
  ITaskPrintBookData,
  IGiftOrderBook,
  IUserGift,
  TUserGift,
  TUserGiftError,
  TUserGiftFirestore,
  IGiftOrderCreateParamsCommon,
  IGiftOrderStandartCreateParams,
  IGiftOrderStandartCreated,
  IGiftOrderStandartPaid,
  IGiftOrderStandartGenerated,
  IGiftOrderSpecialCreateParams,
  IGiftOrderSpecialCreated,
  IGiftOrderSpecialPaid,
  IGiftOrderSpecialGenerated,
  TGiftOrderCreateParams,
  TGiftOrderCreateParamsError,
  TGiftOrderStandard,
  TGiftOrderSpecial,
  TGiftOrder,
  TGiftOrderError,
  TGiftOrderFirestore
> {
  // constructor() {}
}
