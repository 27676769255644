import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'app-final',
  templateUrl: './final.component.html',
  styleUrls: ['./final.component.scss'],
})
export class FinalComponent {
  @Input() barOrderId?: string | undefined;

  constructor(
    public router: Router,
    public media: MediaObserver,
  ) {}
}
