import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Logger } from '@shared/common/logger';

const log = new Logger('FormSignUpFinalComponent');

@Component({
  selector: 'app-form-sign-up-final',
  templateUrl: './form-sign-up-final.component.html',
  styleUrls: ['./form-sign-up-final.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSignUpFinalComponent {
  @Input() email!: string;

  @Output() signUpFinalButtonClick = new EventEmitter<void>();

  // constructor() {}

  public onSignUpFinalButtonClick() {
    log.info('onSignUpFinalButtonClick');
    this.signUpFinalButtonClick.emit();
  }
}
