import { Component } from '@angular/core';
import {
  INPUT_VALUE_COVER_TYPE_SOFT,
  INPUT_VALUE_WRAPPING_STANDART,
} from '@shared/common/book/constants';
import type {
  TBookAlias,
  TBookCover,
  TBookWrapping,
} from '@shared/jp/book/interfaces';
import type { IBook } from '@shared/jp/data/books/types';
import {
  getBooksDiscount,
  getShippingDiscount,
} from '@shared/jp/discount/utils';
import { REGION_NAME_ENGLISH_DEFAULT } from '@shared/jp/shipping/constants';
import { DiscountService } from '@studiobuki/web-core/lib/discount';
import { ProductionService } from 'src/app/services/production.service';
import type { TSectionSteps } from '@studiobuki/web-core/lib/section-steps';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GiftBooks, GiftSteps } from './gift-buy-page.data';

@Component({
  templateUrl: './gift-buy-page.component.html',
  styleUrls: ['./gift-buy-page.component.scss'],
})
export class GiftBuyPageComponent {
  get alias() {
    return this._alias$.value;
  }

  set alias(value: TBookAlias | undefined) {
    this._alias$.next(value);
  }

  private _alias$ = new BehaviorSubject<TBookAlias | undefined>(undefined);

  public cover: TBookCover = INPUT_VALUE_COVER_TYPE_SOFT;

  public wrapping: TBookWrapping = INPUT_VALUE_WRAPPING_STANDART;

  /**
   * mapped with adding `SHIPPING_REGULAR_PRICE` to each `IBookPrice['value']`
   */
  public readonly Books: IBook[] = GiftBooks;

  public readonly sectionSteps: TSectionSteps = GiftSteps;

  get book(): IBook | undefined {
    const { alias, Books: _Books } = this;

    return alias && _Books.find((b) => b.alias === alias);
  }

  public shipping$ = this._production.getShippingRegular$(
    of({ booksCount: 1, regionName: REGION_NAME_ENGLISH_DEFAULT }),
  );

  public discount$ = combineLatest([
    this._discount.activeDiscountCampaign$,
    this._alias$,
  ]).pipe(
    map(
      ([discountCampaign, alias]) =>
        discountCampaign && alias && getBooksDiscount(discountCampaign, alias),
    ),
  );

  public shippingDiscount$ = combineLatest([
    this._discount.activeDiscountCampaign$,
    this.shipping$,
  ]).pipe(
    map(
      ([discountCampaign, shipping]) =>
        discountCampaign && getShippingDiscount(discountCampaign, shipping.id),
    ),
  );

  constructor(
    private _production: ProductionService,
    private _discount: DiscountService,
  ) {}
}
