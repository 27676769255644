import type { OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Logger } from '@shared/common/logger';
import {
  checkError,
  checkUserGift,
  checkUserGiftNotExist,
} from '@shared/jp/gift/functions/checks';
import { ActivatedRoute } from '@angular/router';
import Subscriber from '@shared/common/subscriber';
import { REQUIRED_ERROR } from '@shared/jp/data/books/inputs/validators.constants';
import { LoaderService } from '@studiobuki/web-core/lib/loader';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ROUTE_PATH_PARAM_GIFT_ID } from '@studiobuki/web-core/lib/routing';
import {
  GIFT_EXPIRED_ERROR,
  GIFT_NOT_FOUND_ERROR,
  GIFT_USED_ERROR,
} from './constants';
import type { IGiftReceivedData } from './interfaces';

const log = new Logger('SectionGiftUseComponent');

@Component({
  selector: 'app-section-gift-use',
  templateUrl: './section-gift-use.component.html',
  styleUrls: ['./section-gift-use.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionGiftUseComponent implements OnInit, OnDestroy {
  /** emits only valid IUserGift */
  @Output() giftReceived = new EventEmitter<IGiftReceivedData>();

  private readonly _codeFormControl = new UntypedFormControl(
    '',
    Validators.required,
  );

  public readonly giftFormGroup = new UntypedFormGroup({
    code: this._codeFormControl,
  });

  public readonly codeError$ = new BehaviorSubject('');

  public readonly loading$ = new BehaviorSubject(false);

  private readonly errorsMap = new Map<string, string>();

  private readonly _sub = new Subscriber();

  private readonly _submitSub = new Subscriber();

  public readonly REQUIRED_ERROR = REQUIRED_ERROR;

  constructor(
    private _loaderService: LoaderService,
    private _firebaseService: FirebaseService,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this._sub.push(
      this.loading$.subscribe((status) => {
        if (status) {
          this._loaderService.show();
        } else {
          this._loaderService.hide();
        }
      }),
      this.codeError$.pipe(filter((error) => !!error)).subscribe((error) => {
        this.errorsMap.set(this._codeFormControl.value, error);
        this._codeFormControl.markAsTouched();
        this._codeFormControl.setErrors({ forbidden: true });
      }),
      this._codeFormControl.valueChanges.subscribe((value) => {
        const error = this.errorsMap.get(value) || '';

        this.codeError$.next(error);
      }),
      this._route.paramMap.subscribe(async (paramMap) => {
        const giftId = paramMap.get(ROUTE_PATH_PARAM_GIFT_ID);

        this._codeFormControl.setValue(giftId);

        if (giftId) {
          await this.onSubmit();
        }
      }),
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
    this._submitSub.unsubscribe();
  }

  async onSubmit() {
    this.loading$.next(true);

    const docId = await this._firebaseService.createUserGift(
      this._codeFormControl.value,
    );

    this._submitSub.push(
      this._firebaseService.getUserGift$(docId).subscribe((userGift) => {
        if (checkError(userGift)) {
          const { error } = userGift;

          log.error({ error });
          this.codeError$.next(error.message || '');

          this._submitSub.unsubscribe();
          this.loading$.next(false);
        } else if (checkUserGiftNotExist(userGift)) {
          this.codeError$.next(GIFT_NOT_FOUND_ERROR);

          this._submitSub.unsubscribe();
          this.loading$.next(false);
        } else if (checkUserGift(userGift)) {
          const { applied, expired } = userGift;

          if (expired) {
            this.codeError$.next(GIFT_EXPIRED_ERROR);
          } else if (applied) {
            this.codeError$.next(GIFT_USED_ERROR);
          } else {
            this.giftReceived.emit({ code: userGift.docId, gift: userGift });
          }

          this._submitSub.unsubscribe();
          this.loading$.next(false);
        }
      }),
    );
  }
}
