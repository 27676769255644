import { Injectable } from '@angular/core';
import { LIMIT_BOOKS_FOR_DEFAULT_SHIPPING } from '@shared/common/constants';
import {
  SHIPPING_METHOD_PREMIUM,
  SHIPPING_METHOD_REGULAR,
} from '@shared/common/shipping/constants';
import { getExpectedArrivalDate } from '@shared/jp/production/utils';
import type {
  TRegionNameEnglish,
  TShippingMethod,
} from '@shared/jp/shipping/interfaces';
import {
  formatDate,
  ProductionService as _ProductionService,
} from '@studiobuki/web-core/lib/production';
import {
  getShippingPremium,
  getShippingRegular,
  getShippings,
} from 'src/app/data/shipping/utils';
import type { Observable } from 'rxjs';
import { combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductionService extends _ProductionService<
  TShippingMethod,
  TRegionNameEnglish
> {
  // constructor() { }

  /**
   * @param args$ - an observable containing object with `booksCount` and `regionName`
   * @returns all shippings including disabled
   */
  public getAllShippings$ = (
    args$: Observable<{ booksCount: number; regionName: TRegionNameEnglish }>,
  ) =>
    combineLatest([args$, this.data$, this.expectedShippingDate$]).pipe(
      map(
        ([
          { booksCount, regionName },
          { shippingMethodsMap /* , exceptions */ },
          expectedShippingDate,
        ]) => {
          const disabledShippingMethods: TShippingMethod[] =
            booksCount > LIMIT_BOOKS_FOR_DEFAULT_SHIPPING
              ? [SHIPPING_METHOD_REGULAR]
              : [];

          const shippings = getShippings(
            booksCount,
            expectedShippingDate,
            regionName,
            shippingMethodsMap,
            // exceptions,
          );

          return {
            disabledShippingMethods,
            shippings,
          };
        },
      ),
    );

  /**
   * @param args$ - an observable containing object with `booksCount` and `regionName`
   * @returns all shippings excluding disabled
   */
  public getShippings$ = (
    args$: Observable<{ booksCount: number; regionName: TRegionNameEnglish }>,
  ) =>
    this.getAllShippings$(args$).pipe(
      map(({ shippings, disabledShippingMethods }) =>
        shippings.filter(
          ({ id }) => !(disabledShippingMethods.indexOf(id) > -1),
        ),
      ),
    );

  public getShippingRegular$ = (
    args$: Observable<{ booksCount: number; regionName: TRegionNameEnglish }>,
  ) =>
    combineLatest([args$, this.data$, this.expectedShippingDate$]).pipe(
      map(
        ([
          { booksCount, regionName },
          { shippingMethodsMap /* , exceptions */ },
          expectedShippingDate,
        ]) =>
          getShippingRegular(
            formatDate(
              getExpectedArrivalDate(
                expectedShippingDate,
                regionName,
                shippingMethodsMap[SHIPPING_METHOD_REGULAR],
                // exceptions,
              ),
            ),
            regionName,
            // @ts-ignore
            shippingMethodsMap[SHIPPING_METHOD_REGULAR][regionName].prices[
              booksCount - 1
            ],
          ),
      ),
    );

  public getShippingPremium$ = (
    args$: Observable<{ booksCount: number; regionName: TRegionNameEnglish }>,
  ) =>
    combineLatest([args$, this.data$, this.expectedShippingDate$]).pipe(
      map(
        ([
          { booksCount, regionName },
          { shippingMethodsMap /* , exceptions */ },
          expectedShippingDate,
        ]) =>
          getShippingPremium(
            formatDate(
              getExpectedArrivalDate(
                expectedShippingDate,
                regionName,
                shippingMethodsMap[SHIPPING_METHOD_PREMIUM],
                // exceptions,
              ),
            ),
            regionName,
            // @ts-ignore
            shippingMethodsMap[SHIPPING_METHOD_PREMIUM][regionName].prices[
              booksCount - 1
            ],
          ),
      ),
    );
}
