import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IBookPrice } from '@shared/jp/data/books/prices/types';
import { MediaService } from '@studiobuki/web-core/lib/media';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComponent {
  @Input() id!: string;

  @Input() title!: string;

  /** supports html tags */
  @Input() subtitle!: string;

  @Input() specs!: Record<string, string>;

  @Input() price!: IBookPrice;

  @Input() photo!: string;

  @Input() discount: string | undefined;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  constructor(public media: MediaService) {}

  public onClose() {
    this.close.emit();
  }
}
