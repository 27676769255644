import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import type {
  TRegion,
  TRegionNameEnglish,
  TShippingMethod,
} from '@shared/jp/shipping/interfaces';
import { getRegion, getRegionByNameEnglish } from '@shared/jp/shipping/utils';
import { REGION_NAME_ENGLISH_DEFAULT } from '@shared/jp/shipping/constants';
import { SHIPPING_METHOD_REGULAR } from '@shared/common/shipping/constants';
import type { IAddressShippingData } from '@shared/jp/interfaces';
import type { IFormData } from '../../interfaces';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  @Input() touchEventEmitter!: EventEmitter<void>;

  @Output() dataChange = new EventEmitter<IFormData>();

  @Input() isBackDisabled = false;

  @Input() isNextDisabled = false;

  @Output() backClick = new EventEmitter<void>();

  @Output() nextClick = new EventEmitter<void>();

  public formEmail: IFormData['formEmail'];

  public formShippingAddress: IFormData['formShippingAddress'];

  public readonly environment = environment;

  public readonly parseStateFn = (name: any) => getRegion(name).nameEnglish;

  public readonly regionNameEnglishDefault: TRegionNameEnglish =
    REGION_NAME_ENGLISH_DEFAULT;

  public readonly addressDefault: Omit<IAddressShippingData, 'email'> = {
    firstName: 'John',
    lastName: 'Doe',
    line1: 'Kamimuneoka Shiki',
    line2: 'Kamimuneoka District',
    city: 'Tokyo',
    postalCode: '353-0001',
    country: 'JP',
    state: 'Tokyo',
    phone: '090-0000-0001',
    // email: 'test@mail.com',
  };

  get region(): TRegion | undefined {
    const form = this.formShippingAddress;

    if (form) {
      return getRegionByNameEnglish(form.state);
    }

    return getRegionByNameEnglish(REGION_NAME_ENGLISH_DEFAULT);
  }

  shippingMethod$ = new BehaviorSubject<TShippingMethod>(
    SHIPPING_METHOD_REGULAR,
  );

  // constructor() {}

  public onChange() {
    const data: IFormData = {
      formEmail: this.formEmail,
      formShippingAddress: this.formShippingAddress,
    };

    this.dataChange.emit(data);
  }
}
