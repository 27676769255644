import { Component } from '@angular/core';
import { REGION_NAME_ENGLISH_DEFAULT } from '@shared/jp/shipping/constants';
import type { TRegionNameEnglish } from '@shared/jp/shipping/interfaces';

@Component({
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss'],
})
export class CartPageComponent {
  region: TRegionNameEnglish = REGION_NAME_ENGLISH_DEFAULT;

  // constructor() {}

  onRegionChange(region: TRegionNameEnglish) {
    this.region = region;
  }
}
