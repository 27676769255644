import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import uniqueId from 'lodash/uniqueId';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Logger } from '@shared/common/logger';

const log = new Logger('CopyPlateComponent');

@Component({
  selector: 'app-copy-plate',
  templateUrl: './copy-plate.component.html',
  styleUrls: ['./copy-plate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyPlateComponent {
  @Input() label!: string;

  @Input() toCopy!: string;

  public id: string;

  constructor(private _snackBar: MatSnackBar) {
    this.id = uniqueId('CopyPlateComponent');
  }

  public async copyButtonClick() {
    // todo
    try {
      await navigator.clipboard.writeText(this.toCopy);
      this._snackBar.open('Copied!', undefined, { duration: 1000 });
    } catch (error) {
      log.error(error);
      this._snackBar.open(
        'Something went wrong! Please try again.',
        undefined,
        { duration: 1000 },
      );
    }
  }
}
